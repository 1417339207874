<template>
  <v-container> 
    <v-row class="my-4">
      <v-col>
        <div class="text-h5" color="#000000DE">
          <v-icon left color="#000000DE">mdi-mail</v-icon>
          お問合せ
        </div>
      </v-col>
    </v-row>
    <TableInquiry />
  </v-container>
</template>

<script>
const TableInquiry = () => import('@/components/TableInquiry.vue');

  export default {
    components: {  TableInquiry },
    name: 'Support',
  }
</script>
